<!-- eslint-disable max-len -->
<template>
    <div class="container">
      <div class="my-form">
         <div class="form-header">
           <span>新建保单</span>
         </div>
         <div class="form-body"  :model="formValidate">
           <div class="form-item"  prop="shopName">
              <div class="item">
                <span class="form-text">收单渠道</span><Input v-model="formValidate.shopName" placeholder="请输入收单渠道" style="width: 300px" />
              </div>
               <div class="item">
                <span class="form-text">保险公司</span>
                   <Select v-model="formValidate.companyName" filterable clearable  style="width:300px">
                      <Option v-for="item in companyList" :value="item.companyName" :key="item.cid">{{ item.companyName }}</Option>
                  </Select>
              </div>
            </div>
           <div class="form-item">
              <div class="item">
               <span class="form-text">车牌号码</span><Input v-model="formValidate.carNo" placeholder="请输入公司" style="width: 300px" />
             </div>
              <div class="item">
                    <span class="form-text">联系方式</span><Input v-model="formValidate.phone" placeholder="请输入联系方式" style="width: 300px" />
              </div>
           </div>
           <div class="form-item">
             <div class="item">
               <span class="form-text">保单姓名</span><Input v-model="formValidate.bdName" placeholder="请输入保单姓名" style="width: 300px" />
             </div>
              <div class="item">
              <span class="form-text">机构</span>
                <!-- <span class="form-text">机构</span><Input v-model="formValidate.consumerName" placeholder="请输入机构名称" style="width: 300px" /> -->
                   <Select v-model="formValidate.consumerName" filterable clearable  style="width:300px">
                      <Option v-for="item in organizationList" :value="item.organizationName" :key="item.orId">{{ item.organizationName }}</Option>
                  </Select>
                </div>
           </div>
           <div class="form-item">
               <div class="item">
                <span class="form-text">商业险</span><Input  type="number" v-model="formValidate.sy" placeholder="请输入商业险" style="width: 300px" />
              </div>
              <div class="item">
                <span class="form-text">交强险</span><Input  type="number" v-model="formValidate.jq" placeholder="请输入交强险" style="width: 300px" />
              </div>

           </div>
           <div class="form-item">
              <div class="item">
                <span class="form-text">车船税</span><Input  type="number" v-model="formValidate.ccs" placeholder="请输入车船税" style="width: 300px" />
              </div>
             <div class="item">
               <span class="form-text">非车</span><Input  type="number" v-model="formValidate.jy" placeholder="请输入金额" style="width: 300px" />
             </div>
             <!-- <div class="item">
               <span class="form-text">金额</span><Input  type="number" v-model="formValidate.je1" placeholder="请输入金额" style="width: 300px" />
             </div> -->
           </div>
             <div class="form-item" style="height:65px;">
              <div class="item" style="height:50px;">
                <span class="form-text" style="height:60px;line-height: 60px;">应收比例</span>
                <div class="item-input">
                    <div class="t">商比例</div>
                    <Input v-model="rebateInfo.e"  type="number" placeholder="" style="width: 80px" />
                </div>
                <div class="item-input">
                    <div class="t">交比例</div>
                    <Input v-model="rebateInfo.f"  type="number" placeholder="" style="width: 80px" />
                </div>
                <div class="item-input">
                  <div class="t">税比例</div>
                  <Input v-model="rebateInfo.g"  type="number" placeholder="" style="width: 80px" />
                </div>
                <div class="item-input">
                      <div class="t">非车比例</div>
                      <Input v-model="rebateInfo.h"  type="number" placeholder="" style="width: 80px" />
                </div>
              </div>
              <div class="item"  style="height:50px;">
                  <span class="form-text" style="height:60px;line-height: 60px;">应付比例</span>
                  <div class="item-input">
                      <div class="t">商比例</div>
                      <Input v-model="rebateInfo.a" type="number" placeholder="" style="width: 80px" />
                  </div>
                  <div class="item-input">
                      <div class="t">交比例</div>
                      <Input v-model="rebateInfo.b" type="number" placeholder="" style="width: 80px" />
                  </div>
                  <div class="item-input">
                      <div class="t">税比例</div>
                      <Input v-model="rebateInfo.c" type="number" placeholder="" style="width: 80px" />
                  </div>
                  <div class="item-input">
                      <div class="t">非车比例</div>
                      <Input v-model="rebateInfo.d"  type="number" placeholder="" style="width: 80px" />
                  </div>
              </div>
             </div>
             <!-- <div class="form-item">
               <div class="item">
                  <span class="form-text">交强/车船机构</span>
                   <Select v-model="formValidate.jqOrg" filterable clearable  style="width:300px">
                      <Option v-for="item in organizationList" :value="item.organizationName" :key="item.orId">{{ item.organizationName }}</Option>
                   </Select>
               </div>
                <div class="item">
                  <span class="form-text">驾意险机构</span>
                    <Select v-model="formValidate.jyOrg" filterable clearable  style="width:300px">
                      <Option v-for="item in organizationList" :value="item.organizationName" :key="item.orId">{{ item.organizationName }}</Option>
                    </Select>
                </div>
           </div>   -->
            <div class="form-item">
              <div class="item">
                <span class="form-text">业务员</span>
                <Select v-model="formValidate.source" filterable clearable  style="width:300px">
                        <Option v-for="item in sourceList" :value="item.source" :key="item.sid">{{ item.source }}</Option>
                </Select>
             </div>
             <div class="item">
                 <span class="form-text">车辆类型</span>
                 <Select v-model="formValidate.ctId" filterable clearable  style="width:300px">
                        <Option v-for="item in carTypeList" :value="item.ctId" :key="item.ctId">{{ item.name }}</Option>
                </Select>
             </div>
           </div>
          <div class="form-item">
             <div class="item">
               <span class="form-text">应收返点</span>
               <Input   type="number" v-model="calcYs" :readonly="true" placeholder="请输入金额" style="width: 300px;"/>
             </div>
             <div class="item">
               <span class="form-text">应付返点</span><Input :readonly="true"    type="number" v-model="calcYf" placeholder="请输入金额" style="width: 300px" />
             </div>
           </div>
           <div class="form-item">
                <div class="item">
               <span class="form-text">垫付金额</span><Input    type="number" v-model="formValidate.df" placeholder="请输入盈亏" style="width: 300px" />
             </div>
              <div class="item">
               <span class="form-text">返点金额</span><Input  type="number" v-model="formValidate.rebateAmount" placeholder="请输入返点金额" style="width: 300px" />
             </div>
           </div>
           <div class="form-item">
              <div class="item">
               <span class="form-text">盈亏</span><Input :readonly="true"    type="number" v-model="calcYk" placeholder="请输入盈亏" style="width: 300px" />
             </div>
               <div class="item">
               <span class="form-text">备注</span><Input v-model="formValidate.remark" placeholder="请输入备注信息" style="width: 300px" />
             </div>
           </div>
          <!-- 上传行驶证 开始 -->
           <div class="form-item">
            <div class="file-item">
              <span class="form-text">行驶证</span>
              <div class="demo-upload-list" v-for="(item, index) in uploadList" :key="index">
                <template v-if="item.status === 'finished'">
                    <img :src="item.url">
                    <div class="demo-upload-list-cover">
                        <Icon type="ios-eye-outline" @click.native="handleView(item.url)"></Icon>
                        <Icon type="ios-trash-outline" @click.native="handleRemove(item)"></Icon>
                    </div>
                </template>
                <template v-else>
                    <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
                </template>
            </div>
              <Upload
                ref="uploadXsz"
                :show-upload-list="false"
                :default-file-list="defaultList"
                :on-success="handleSuccess"
                :format="['jpg','jpeg','png']"
                :max-size="5120"
                :on-format-error="handleFormatError"
                :on-exceeded-size="handleMaxSize"
                :before-upload="handleBeforeUpload"
                multiple
                type="drag"
                :action="action"
                style="display: inline-block;width:58px;">
                <div style="width: 58px;height:58px;line-height: 58px;">
                    <Icon type="ios-camera" size="20"></Icon>
                </div>
            </Upload>
            </div>
            <div class="item">
            </div>
           </div>
          <!-- 上传身份证 开始 -->
           <div class="form-item">
            <div class="file-item">
              <span class="form-text">身份证</span>
              <div class="demo-upload-list" v-for="(item, index) in uploadSfzList" :key="index">
                <template v-if="item.status === 'finished'">
                    <img :src="item.url">
                    <div class="demo-upload-list-cover">
                        <Icon type="ios-eye-outline" @click.native="handleView(item.url)"></Icon>
                        <Icon type="ios-trash-outline" @click.native="handleSfzRemove(item)"></Icon>
                    </div>
                </template>
                <template v-else>
                    <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
                </template>
            </div>
              <Upload
                ref="uploadSfz"
                :show-upload-list="false"
                :default-file-list="defaultSfzList"
                :on-success="handleSfzSuccess"
                :format="['jpg','jpeg','png']"
                :max-size="5120"
                :on-format-error="handleSfzFormatError"
                :on-exceeded-size="handleSfzMaxSize"
                :before-upload="handleSfzBeforeUpload"
                multiple
                type="drag"
                :action="action"
                style="display: inline-block;width:58px;">
                <div style="width: 58px;height:58px;line-height: 58px;">
                    <Icon type="ios-camera" size="20"></Icon>
                </div>
            </Upload>
            </div>
            <div class="item">
            </div>
           </div>


          <!-- 保单文件上传 开始 -->
          <div class="form-item">
            <div class="file-item">
              <span class="form-text">保单文件</span>
              <div class="demo-upload-list" v-for="(item, index) in uploadBdList" :key="index">
                <template v-if="item.status === 'finished'">
                    <span>{{ item.name }}</span>
                    <div class="demo-upload-list-cover">
                        <!-- <Icon type="ios-eye-outline" @click.native="handleView(item.url)"></Icon> -->
                        <Icon type="ios-trash-outline" @click.native="handleSfzRemove(item)"></Icon>
                    </div>
                </template>
              </div>
              <Upload
                ref="uploadBd"
                :show-upload-list="false"
                :default-file-list="defaultBdList"
                :on-success="handleBdSuccess"
                :format="['pdf']"
                :max-size="10240"
                :on-format-error="handleBdFormatError"
                :on-exceeded-size="handleBdMaxSize"
                :before-upload="handleBdBeforeUpload"
                :on-remove="handleBdRemove"
                type="drag"
                :action="action"
                style="display: inline-block;width:100px;"
                >
                <div style="width: 58px;height:58px;line-height: 58px; margin: 0 auto;">
                    <Icon type="ios-cloud-upload-outline" size="20"></Icon>
                </div>
            </Upload>
            </div>
            <div class="item">
            </div>
           </div>
           <!-- <div class="form-item">
              <div class="item">
                <span class="form-text">录单时间</span>
                  <DatePicker type="date" placeholder="请选择录单时间" v-model="formValidate.createTime" style="width: 300px" />
             </div>
               <div class="item">

             </div>
           </div> -->
          <!-- <div class="form-item">
             <div class="item">
               <span class="form-text">驾意盈亏</span><Input disabled  type="number" v-model="formValidate.jyYk" placeholder="请输入金额" style="width: 300px" />
             </div>
             <div class="item">
               <span class="form-text">驾意返点</span><Input disabled  type="number" v-model="formValidate.jyRebateAmount" placeholder="请输入金额" style="width: 300px" />
             </div>
           </div> -->


           <!-- <div class="form-item" style="height:100px;padding-top:30px;">
              <div class="item">
                <span class="form-text" style="height:100px;line-height: 100px;">应收比例</span>
                <div class="item-input">
                    <div>商比例</div>
                    <Input v-model="rebateInfo.e"  type="number" placeholder="" style="width: 80px" />
                </div>
              <div class="item-input">
                <div>交比例</div>
                  <Input v-model="rebateInfo.f"  type="number" placeholder="" style="width: 80px" />
              </div>

              <div class="item-input">
                <div>税比例</div>
                <Input v-model="rebateInfo.g"  type="number" placeholder="" style="width: 80px" />
                </div>
                <div class="item-input">
                <div>非车比例</div>
                <Input v-model="rebateInfo.h"  type="number" placeholder="" style="width: 80px" />
                </div>
              </div>
             </div>


            <div class="form-item" style="height:100px;padding-top:30px">
            <div class="item">
              <span class="form-text" style="height:100px;line-height: 100px;">应付比例</span>
              <div class="item-input">
              <div>商比例</div>
              <Input v-model="rebateInfo.a" type="number" placeholder="" style="width: 80px" />
              </div>
              <div class="item-input">
              <div>交比例</div>
              <Input v-model="rebateInfo.b" type="number" placeholder="" style="width: 80px" />
              </div>
              <div class="item-input">
              <div>税比例</div>
              <Input v-model="rebateInfo.c" type="number" placeholder="" style="width: 80px" />
              </div>
               <div class="item-input">
              <div>非车比例</div>
              <Input v-model="rebateInfo.d"  type="number" placeholder="" style="width: 80px" />
              </div>
             </div>
           </div> -->

          <div class="form-item button-item">
            <div class="item">
              <Button @click="handleReset()" style="margin-left: 8px">重置</Button>
            </div>
            <div class="item">
              <Button type="primary" @click="handleSubmit()">提交</Button>
             </div>
          </div>
         </div>
         <!-- form-body end -->
      </div>

      <Modal title="View Image" v-model="visible">
        <img :src="imgName" v-if="visible" style="width: 100%">
      </Modal>
    </div>

</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import * as math from 'mathjs'
// eslint-disable-next-line max-len
import { addBusinessVo, findInsuranceTypeList, findCompanyVoList, findOrganizationList, findSourceList, findCarTypeList } from '@/api'

export default {
  name: 'saveForm',
  data() {
    return {
      action: process.env.VUE_APP_SERVER_URL + '/file/upload',
      shopName: '',
      carTypeList: [],
      formValidate: {
          ctId: '',
          shopName: '',
          companyName: '',
          carNo: '',
          consumerName: '',
          bdName: '',
          sy: 0,
          jq: 0,
          ccs: 0,
          jy: 0,
          je1: 0,
          payTatol: 0,
          rebate: '',
          yk: 0,
          ys: 0,
          yf: 0,
          df: 0,
          phone: '',
          rebateAmount: 0.0,
          remark: '',
          source: '',
          jyYk: 0,
          jyRebateAmount: 0,
          jyRebate: '',
          jqOrg: '',
          jyOrg: '',
          sfz: '',
          xsz: '',
          bd: '',
          // createTime: new Date(),
      },
      rebateInfo: {
        a: 0,
        b: 0,
        c: 0,
        d: 0,
        e: 0,
        f: 0,
        g: 0,
        h: 0,
      },
      InsuranceTypeList: [],
      ruleValidate: {},
      companyList: [],
      organizationList: [],
      sourceList: [],
      carType: '',
      uploadList: [],
      uploadSfzList: [],
      uploadBdList: [],
      defaultList: [],
      defaultSfzList: [],
      defaultBdList: [],
      imgName: '',
      visible: false,
    }
  },
  mounted() {
    // 设置表格高度
    const t = this
    t.maxHeight = window.innerHeight - 240
    // this.findAllRole();
    // this.findPermissList();
    t.findSourceList()
    t.findCompanyVoList()
    t.findOrganizationList()
    t.findCarTypeList()
    // t.uploadList = t.$refs.uploadXsz.fileList
    // t.uploadSfzList = t.$refs.uploadSfz.fileList
    // t.uploadBdList = t.$refs.uploadBd.fileList
  },
created() {

  },
methods: {
      handleReset() {
        const keys = Object.keys(this.formValidate)
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i]
            if (this.formValidate[key].constructor == Number) {
              this.formValidate[key] = 0
            }
            if (this.formValidate[key].constructor == String) {
              this.formValidate[key] = ''
            }
        }
        this.rebateInfo = {
                      a: 0,
                      b: 0,
                      c: 0,
                      d: 0,
                      e: 0,
                      f: 0,
                      g: 0,
                      h: 0,
        }

        this.$refs.uploadXsz.clearFiles()
        this.$refs.uploadSfz.clearFiles()
        this.$refs.uploadBd.clearFiles()
        this.defaultSfzList = []
        this.defaultList = []
        this.defaultBdList = []
        this.uploadList = []
        this.uploadSfzList = []
        this.uploadBdList = []
      },
      handleSubmit() {
        if (!this.formValidate.shopName || this.formValidate.shopName.length <= 0) {
              this.$Message.warning('收单渠道不能为空')
              return
        }
        if (!this.formValidate.companyName || this.formValidate.companyName.length <= 0) {
              this.$Message.warning('保险公司不能为空')
              return
        }
        if (!this.formValidate.carNo || this.formValidate.carNo.length <= 0) {
              this.$Message.warning('车牌号码不能为空')
              return
        }
           if (!this.formValidate.phone || this.formValidate.phone.length <= 0) {
              this.$Message.warning('联系方式不能为空')
              return
        }
        if (!this.formValidate.consumerName || this.formValidate.consumerName.length <= 0) {
              this.$Message.warning('机构不能为空')
              return
        }
         if (!this.formValidate.sy || this.formValidate.sy.length <= 0) {
              this.$Message.warning('商业险不能为空')
              return
        }
        if (!this.formValidate.jq || this.formValidate.jq.length <= 0) {
              this.$Message.warning('交强险不能为空')
              return
        }
        if (!this.formValidate.ccs || this.formValidate.ccs.length <= 0) {
              this.$Message.warning('车船税不能为空')
              return
        }
        if (!this.formValidate.bdName || this.formValidate.bdName.length <= 0) {
              this.$Message.warning('保单姓名不能为空')
              return
        }
        if (!this.formValidate.source || this.formValidate.source === 0) {
            this.$Message.warning('请选择业务员')
            return
        }
        if (!this.formValidate.ctId || this.formValidate.ctId.length <= 0) {
              this.$Message.warning('请选择车辆类型')
              return
        }
        // if (!this.formValidate.createTime) {
        //   this.$Message.warning('请选择录单时间')
        //   return
        // }

        // if (this.formValidate.je1.length <= 0){
        //       this.$Message.warning('金额不能为空');
        //       return
        // }
        // if (this.formValidate.payTatol.length <= 0){
        //       this.$Message.warning('保费总计不能为空');
        //       return
        // }
        // if (!this.formValidate.rebate || this.formValidate.rebate.length <= 0){
        //       this.$Message.warning('返点详情不能为空');
        //       return
        // }
        if (!this.formValidate.df || this.formValidate.df.length < 0) {
               this.formValidate.df = 0
        }
        if (!this.formValidate.rebateAmount || this.formValidate.rebateAmount.length <= 0) {
               this.formValidate.rebateAmount = 0
        }
        if (!this.formValidate.consumerName || this.formValidate.consumerName.length === 0) {
          this.formValidate.consumerName = ''
        }
        if (!this.formValidate.jqOrg || this.formValidate.jqOrg.length === 0) {
          this.formValidate.jqOrg = ''
        }
        if (!this.formValidate.jyOrg || this.formValidate.jyOrg.length === 0) {
          this.formValidate.jyOrg = ''
        }
        // 应收
        this.formValidate.jyRebateAmount = this.calcYs
        // 应付
        this.formValidate.jyYk = this.calcYf

        this.formValidate.yk = this.calcYk
        // 保存返点比
        const data = this.rebateInfo
        this.formValidate.jyRebate = JSON.stringify(data)
        this.formValidate.uid = localStorage.getItem('uid')
        // this.formValidate.createTime = this.formValidate.createTime.getTime()
        addBusinessVo(this.formValidate).then(res => {
          if (res.code === 200) {
            this.$Message.success('保存成功')
            this.handleReset()
          } else {
            this.$Message.warning('保存失败')
          }
        })
      },
      findInsuranceTypeList() {
          findInsuranceTypeList().then(res => {
                this.InsuranceTypeList = res.data
                console.log(res)
          })
        },
        findCompanyVoList() {
          findCompanyVoList().then(res => {
                this.companyList = res.data
          })
        },
        findOrganizationList() {
          let data = 'pageIndex=1&pageSize=100000'
            findOrganizationList(data).then(res => {
              this.organizationList = res.data.list
            })
        },
        calc() {
           const t = this
           const data = t.rebateInfo
           const sy = t.formValidate.sy
           const jq = t.formValidate.jq
           const ccs = t.formValidate.ccs
           const jy = t.formValidate.jy

           const a = data.a === 0 ? 0 : data.a / 100
           const b = data.b === 0 ? 0 : data.b / 100
           const c = data.c === 0 ? 0 : data.c / 100
           const d = data.d === 0 ? 0 : data.d / 100


           const e = (data.e - data.a) === 0 ? 0 : (data.e - data.a) / 100
           const f = (data.f - data.b) === 0 ? 0 : (data.f - data.b) / 100
           const g = (data.g - data.c) === 0 ? 0 : (data.g - data.c) / 100
           const h = (data.h - data.d) === 0 ? 0 : (data.h - data.d) / 100

          const fd = sy * a + jq * b + ccs * c
          const yk = sy * e + jq * f + ccs * g

          const jyFd = jy * d
          const jyYk = jy * h
          const rebate = data.a + '/' + data.b + '/' + data.c + '-' + data.e + '/' + data.f + '/' + data.g
          const jyRebate = data.d + '-' + data.h
          t.formValidate.rebateAmount = fd.toFixed(2)
          t.formValidate.yk = yk.toFixed(2)
          t.formValidate.rebate = rebate
          t.formValidate.jyRebateAmount = jyFd.toFixed(2)
          t.formValidate.jyYk = jyYk.toFixed(2)
          t.formValidate.jyRebate = jyRebate
        },
        printFn(value) {
                const precision = 14
                return Number(math.format(value, precision))
            },
        findSourceList() {
          let data = 'pageIndex=1&pageSize=100000'
          findSourceList(data).then(res => {
              this.sourceList = res.data.list
          })
        },
        findCarTypeList() {
          let data = `pageSize=10000&pageIndex=1&carType=${this.carType}`
          findCarTypeList(data).then(res => {
            this.carTypeList = res.data.list
          })
        },
        handleView(name) {
                this.imgName = name
                this.visible = true
            },
        handleRemove(file) {
            const fileList = this.$refs.uploadXsz.fileList
            this.$refs.uploadXsz.fileList.splice(fileList.indexOf(file), 1)
            this.uploadList = this.$refs.uploadXsz.fileList
        },
        handleSfzRemove(file) {
            const fileList = this.$refs.uploadSfz.fileList
            this.$refs.uploadSfz.fileList.splice(fileList.indexOf(file), 1)
            this.uploadSfzList = this.$refs.uploadSfz.fileList
        },
        handleBdRemove(file) {
            const fileList = this.$refs.uploadBd.fileList
            this.$refs.uploadBd.fileList.splice(fileList.indexOf(file), 1)
            this.uploadBdList = this.$refs.uploadBd.fileList
        },
        handleSuccess(res, file) {
          this.uploadList = this.$refs.uploadXsz.fileList
          file.url = res
          file.name = file.name
          let xsz = this.$refs.uploadXsz.fileList.map(item => item.url)
          this.formValidate.xsz = xsz.join(',')
        },
        handleSfzSuccess(res, file) {
          console.log(res)
          this.uploadSfzList = this.$refs.uploadSfz.fileList
          file.url = res
          file.name = file.name
          let sfz = this.$refs.uploadSfz.fileList.map(item => item.url)
          this.formValidate.sfz = sfz.join(',')
        },
        handleBdSuccess(res, file) {
          console.log(file)
          this.uploadBdList = this.$refs.uploadBd.fileList
          file.url = res
          file.name = file.name
          let bd = this.$refs.uploadBd.fileList.map(item => item.url)
          this.formValidate.bd = bd.join(',')
        },
        handleFormatError(file) {
            this.$Notice.warning({
                title: '文件格式不正确',
                desc: file.name + '文件格式不正确,请选择jpg或png.',
            })
        },
        handleSfzFormatError(file) {
            this.$Notice.warning({
                title: '文件格式不正确',
                desc: file.name + '文件格式不正确,请选择jpg或png.',
            })
        },
        handleBdFormatError(file) {
            this.$Notice.warning({
                title: '文件格式不正确',
                desc: file.name + '文件格式不正确,请选择PDF.',
            })
        },
        handleMaxSize(file) {
            this.$Notice.warning({
                title: '超过文件大小限制',
                desc: '文件' + file.name + ' 太大, 不能超过5M.',
            })
        },
        handleSfzMaxSize(file) {
            this.$Notice.warning({
                title: '超过文件大小限制',
                desc: '文件' + file.name + ' 太大, 不能超过5M.',
            })
        },
        handleBdMaxSize(file) {
            this.$Notice.warning({
                title: '超过文件大小限制',
                desc: '文件' + file.name + ' 太大, 不能超过10M.',
            })
        },
        handleBeforeUpload() {
            const check = this.uploadList.length < 4
            if (!check) {
                this.$Notice.warning({
                    title: '最多可上传四张图片',
                })
            }
            return check
        },
        handleSfzBeforeUpload() {
            const check = this.uploadSfzList.length < 2
            if (!check) {
                this.$Notice.warning({
                    title: '最多可上传两张图片',
                })
            }
            return check
        },
         handleBdBeforeUpload() {
            const check = this.uploadBdList.length < 4

            console.log({
              check,
              aa: this.uploadBdList.length,
            })
            if (!check) {
                this.$Notice.warning({
                    title: '最多可上传四份文件',
                })
            }
            return check
        },
  },
  computed: {
    // 计算应收返点
    calcYs() {
           let t = this
           const sy = t.formValidate.sy
           const jq = t.formValidate.jq
           const ccs = t.formValidate.ccs
           const jy = t.formValidate.jy

           const data = t.rebateInfo
           const e = data.e === 0 ? 0 : data.e / 100
           const f = data.f === 0 ? 0 : data.f / 100
           const g = data.g === 0 ? 0 : data.g / 100
           const h = data.h === 0 ? 0 : data.h / 100
      return (parseFloat(sy * e) + parseFloat(jq * f) + parseFloat(ccs * g) + parseFloat(jy * h)).toFixed(2)
    },
    // 计算应付返点
     calcYf() {
      const t = this
      const data = t.rebateInfo

      const sy = t.formValidate.sy
      const jq = t.formValidate.jq
      const ccs = t.formValidate.ccs
      const jy = t.formValidate.jy

      const a = data.a === 0 ? 0 : data.a / 100
      const b = data.b === 0 ? 0 : data.b / 100
      const c = data.c === 0 ? 0 : data.c / 100
      const d = data.d === 0 ? 0 : data.d / 100

      return (parseFloat(sy * a) + parseFloat(jq * b) + parseFloat(ccs * c) + parseFloat(jy * d)).toFixed(2)
    },
    // 计算盈亏
    calcYk() {
      let t = this
      const sy = t.formValidate.sy
      const jq = t.formValidate.jq
      const ccs = t.formValidate.ccs
      const jy = t.formValidate.jy

      const data = t.rebateInfo
      const e = data.e === 0 ? 0 : data.e / 100
      const f = data.f === 0 ? 0 : data.f / 100
      const g = data.g === 0 ? 0 : data.g / 100
      const h = data.h === 0 ? 0 : data.h / 100

      const a = data.a === 0 ? 0 : data.a / 100
      const b = data.b === 0 ? 0 : data.b / 100
      const c = data.c === 0 ? 0 : data.c / 100
      const d = data.d === 0 ? 0 : data.d / 100

      let ys = parseFloat(sy * e) + parseFloat(jq * f) + parseFloat(ccs * g) + parseFloat(jy * h)
      let yf = parseFloat(sy * a) + parseFloat(jq * b) + parseFloat(ccs * c) + parseFloat(jy * d)

      let fd = t.formValidate.rebateAmount
      return Number(ys - yf - fd).toFixed(2)
    },
  },
}
</script>

<style lang="scss">
  .view-c{
    overflow-y: scroll !important;
  }
    .my-form{
      overflow-x:scroll;
      overflow-y: scroll;
    }
   .form-header{
     font-size: 30px;
     text-align: center;
     border-bottom: 1px solid #e8eaec;
     color: #464c5b;
     font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
     margin-bottom: 10px;
     overflow-y: scroll;
   }
   .form-body{
     width: 1100px;
     max-width: 1100px;
     margin: 0 auto;
     .form-item{
       display: flex;
       justify-content: space-between;
       flex-direction: row;
       flex-wrap: wrap;
       justify-items: center;
       .item{
        height: 30px;
        line-height: 50px;
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        width: 45%;
        .item-input{
          width: 100px;
          height: 60px;
          line-height: 60px;
          text-align: left;
          display: flex;
          flex-direction: column;
          .t{
            height: 25px;
            line-height: 25px;
            color: #aaa;
          }
        }
       }
       .file-item{
          width: auto;
          height: 60px;
          display: flex;
          flex-direction: row;
          align-items: center;
       }


     }
     .button-item{
         width:600px;
         height:100px;
         line-height: 100px;
         margin:0 auto;
         align-items: center;
       }
      .form-text{
        text-align: center;
        display:inline-block;
        height: 30px;
        line-height: 30px;
        width: 90px;
      }

     .ivu-select-selected-value{
       width: 100%;
     }
   }
   .demo-upload-list{
        display: inline-block;
        width: 120px;
        height: 60px;
        text-align: center;
        line-height: 60px;
        border: 1px solid transparent;
        border-radius: 4px;
        overflow: hidden;
        background: #fff;
        position: relative;
        box-shadow: 0 1px 1px rgba(0,0,0,.2);
        margin-right: 4px;
    }
    .demo-upload-list img{
        width: 100%;
        height: 100%;
    }
    .demo-upload-list-cover{
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0,0,0,.6);
    }
    .demo-upload-list:hover .demo-upload-list-cover{
        display: block;
    }
    .demo-upload-list-cover i{
        color: #fff;
        font-size: 20px;
        cursor: pointer;
        margin: 0 2px;
    }
</style>